<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="450">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('add')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Assign Profile
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                        <v-text-field
                        dense
                        outlined
                        v-model="form[0].email"
                        :rules="form[0].rule"
                        label="Email"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[2].selected"
                        :items="form[2].type"
                        :rules="form[2].rule"
                        item-text="name"
                        item-value="ID"
                        label="Select Type"
                        outlined
                        hide-details="auto"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[3].selected"
                        :items="form[3].role"
                        :rules="form[3].rule"
                        item-text="name"
                        item-value="ID"
                        label="Select Role"
                        outlined
                        hide-details="auto"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[4].selected"
                        :items="form[4].operator"
                        :rules="form[4].rule"
                        item-text="name"
                        item-value="OperatorID"
                        label="Select Operator"
                        outlined
                        @change="getAllCarpark"
                        hide-details="auto"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[5].selected"
                        :items="form[5].carpark"
                        :rules="form[5].rule"
                        item-text="name"
                        item-value="CarParkID"
                        label="Select Car Park"
                        outlined
                        hide-details="auto"
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: [ 
                {email: '', rule: [] },
                {header: [{text: 'ID', value: 'ID'}, {text: 'Role Name', value: "RoleName"}], items: [], rule: [], selected: [] },
                {type: [], rule: [], selected: '' },
                {role: [], rule: [], selected: '' },
                {operator: [], rule: [], selected: '' },
                {carpark: [], rule: [], selected: '' },
            ],
            search: '',

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            tableloading: false
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
                this.getAllType();
                this.getAllRole();
                this.getAllOperator();
                this.getAllCarpark();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    created(){
        
    },

    methods: {
        async getAllRole(){
            let response = await this.allInfo.profilerole;
            let dt = response;
            this.form[3].role.push({name: "Select Role", ID: ""});
            for(var i=0; i<dt.length; i++){
                dt[i].name = `${dt[i].ID} - ${dt[i].RoleName}`;
                this.form[3].role.push(dt[i]);
            }
        },

        async getAllType(){
                let response = await this.allInfo.profiletype;
                let dt = response;
                this.form[2].type.push({name: "Select Type", ID: ""});
                for(var i=0; i<dt.length; i++){
                    dt[i].name = `${dt[i].ID} - ${dt[i].TypeName}`;
                    this.form[2].type.push(dt[i]);
                }
        },

        async getAllOperator(){
                let response = await this.allInfo.operator;
                let dt = response;
                this.form[4].operator.push({name: "Select Operator", OperatorID: ""});
                this.form[4].operator.push({name: "All", OperatorID: "4ALLOP"});
                for(var i=0; i<dt.length; i++){
                    dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                    this.form[4].operator.push(dt[i]);
                }
        },

        async getAllCarpark(){
            let self = this;
            let response = await this.allInfo.carpark;
            let dt = response;

            this.form[5].carpark = [];
            this.form[5].selected = '';

            if (this.form[4].selected){
                let items = dt.filter(item => {
                return item.OperatorID == self.form[4].selected;
                });

                this.form[5].carpark.push({name: "Select Car Park", CarParkID: ""});
                this.form[5].carpark.push({name: "All", CarParkID: "4ALLCP"});
                for(var i=0; i<items.length; i++){
                    items[i].name = `${items[i].CarParkID} - ${items[i].CarParkName}`;
                    this.form[5].carpark.push(items[i]);
                }
            }

            else{
                this.form[5].carpark.push({name: "Select Car Park", CarParkID: ""});
                this.form[5].carpark.push({name: "All", CarParkID: "4ALLCP"});
                for(var i=0; i<dt.length; i++){
                    dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                    this.form[5].carpark.push(dt[i]);
                }
            }
        },

        onRuleChange(after, before) {
            this.form[0].rule = [];
            this.form[1].rule = [];
            this.form[2].rule = [];
            this.form[3].rule = [];
            this.form[4].rule = [];
            this.form[5].rule = [];
        },

        async beforeCreateForm(flag){
            this.form[0].rule = [
                v => !!v || 'Email is required',
                v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Please enter a valid email address',
            ];

            this.form[3].rule = [
                v => !!v || 'Role is required',
            ];

            this.form[2].rule = [
                v => !!v || 'Type is required',
            ];

            this.form[4].rule = [
                v => !!v || 'Operator is required',
            ];

            this.form[5].rule = [
                v => !!v || 'Car Park is required',
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.onRuleChange();
            this.form[0].email = this.modalInfo.email;
            this.form[1].items = [];
            this.form[1].selected = [];
            this.form[2].selected = '';
            this.form[3].selected = '';
            this.form[4].selected = '';
            this.form[5].selected = '';
            this.search = '';
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
          //console.log(value);
                if (this.ma.modal_action_type == 'add'){
                    this.form.flag = 'add';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
}
</script>